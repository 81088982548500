//测试数据
const listData = [{
    header: "华东",
    checkAll: false, // 全选
    isIndeterminate: false, // 不确定状态
    checkedCities: [], // 选择的城市
    cities: ['上海市','江苏省','浙江省','安徽省','江西省'] // 城市数据
},{
    header: "华南",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['福建省','广东省','广西壮族自治区','海南省']
},{
    header: "华中",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['河南省','湖北省','湖南省']
},{
    header: "华北",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['北京市','天津市','河北省','山西省','内蒙古自治区','山东省']
},{
    header: "西南",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['重庆市','四川省','贵州省','云南省','西藏自治区']
},{
    header: "东北",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['辽宁省','吉林省','黑龙江省']
},{
    header: "西北",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['陕西省','甘肃省','青海省','宁夏回族自治区','新疆维吾尔族自治区']
}, {
    header: "港澳台",
    checkAll: false,
    isIndeterminate: false,
    checkedCities: [],
    cities: ['台湾省', '香港特别行政区', '澳门特别行政区']
}
]

export default listData
