<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>物流管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/freightTemp' }">运费模板</el-breadcrumb-item>
        <el-breadcrumb-item>{{ pageType === 'add' ? '新增' : pageType === 'edit' ? '编辑' : '详情' }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="shebei">基本信息</div>
        <div class="content_li-padding">
          <el-form :model="template" :rules="tempRules" ref="tempRuleForm" label-width="110px" class="demo-ruleForm">
            <el-form-item label="模板名称" prop="title">
              <span v-if="pageType === 'details'">{{ template.title }}</span>
              <el-input v-else class="template-title" v-model="template.title" placeholder="请输入模板名称"></el-input>
            </el-form-item>
            <el-form-item label="发货地区：" prop="simAddressList">
              <span v-if="pageType === 'details'">{{ template.simAddress }}</span>
              <area-cascader v-else type="text" :placeholder="template.simAddress"
                             v-model="template.simAddressList"
                             :data="$pcaa"
                             :level="1"></area-cascader>
            </el-form-item>
            <el-form-item label="运费设置：" prop="type">
              <span
                  v-if="pageType === 'details'">{{ template.type === 0 ? '阶梯计价' : template.type === 1 ? '固定运费' : '-' }}</span>
              <el-radio-group v-model="template.type" v-else>
                <el-radio :label="0">阶梯计价</el-radio>
                <el-radio :label="1">固定运费</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="运费金额：" prop="freight" v-if="template.type === 1">
              <span v-if="pageType === 'details'">{{ template.freight }}</span>
              <div v-else class="temp">
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01"
                                 :precision="2" :min="0" v-model="template.freight"
                                 placeholder="请输入运费金额"></el-input-number>
                <span>元</span></div>
            </el-form-item>
            <el-form-item label="计件方式" prop="valuationWay" v-if="template.type === 0">
              <span
                  v-if="pageType === 'details'">{{ template.valuationWay === 0 ? '按件数' : template.valuationWay === 1 ? '按重量' : '-' }}</span>
              <el-radio-group v-else v-model="template.valuationWay">
                <el-radio :label="0">按件数</el-radio>
                <el-radio :label="1">按重量</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="默认运费：" v-if="template.type === 0" prop="defaultNumber">
              <div v-if="pageType === 'details'">
                <span>{{ template.defaultNumber }}{{ template.valuationWay === 1 ? 'kg' : '件' }}以内，{{ template.defaultPrice }}元</span>
                <span v-if="template.newNumber !== 0">，每增加{{template.newNumber}}{{ template.valuationWay === 1 ? 'kg' : '件' }}，增加运费{{template.newPrice}}元</span>
              </div>
              <div class="flexRow temp" v-else>
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="1" :min="0"
                                 v-model="template.defaultNumber" placeholder="请输入"></el-input-number>
                <span>{{ template.valuationWay === 1 ? 'kg' : '件' }}以内，</span>
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01"
                                 :precision="2" :min="0" v-model="template.defaultPrice"
                                 placeholder="请输入"></el-input-number>
                <span>元，每增加</span>
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="1" :min="0"
                                 v-model="template.newNumber" placeholder="请输入"></el-input-number>
                <span>{{ template.valuationWay === 1 ? 'kg' : '件' }}，增加运费</span>
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01" :min="0"
                                 :precision="2" v-model="template.newPrice" placeholder="请输入"></el-input-number>
                <span>元</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="shebei">基本信息</div>
        <div class="content_li-padding">
          <el-tabs v-model="activeName" :before-leave="handleBeforeLeave">
            <el-tab-pane label="指定地区运费" name="first">
              <div class="flexRow justifyContentSpac alignItems">
                <div class="table-title">除指定地区外，其余地区运费采用：“默认运费”</div>
                <div class="flexRow" v-if="pageType !== 'details'">
                  <el-button @click="delRuls">删除</el-button>
                  <el-button type="primary" @click="addRuls">添加地区</el-button>
                </div>
              </div>
              <el-form class="form-table" ref="ruleForm1" :model="form" :rules="tableRules1">
                <el-table
                    border
                    :data="form.list"
                    v-loading="tableLoading"
                    style="width: 100%"
                    :header-cell-style="this.utils.getRowClass"
                    @selection-change="handleSelectionChange">
                  <el-table-column
                      align="center"
                      type="selection"
                      width="55">
                  </el-table-column>
                  <el-table-column prop="city" label="运送地区" min-width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <span>{{ scope.row.city ? scope.row.city : '-' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="计费规则" min-width="250">
                    <template slot-scope="scope">
                      <el-form-item class="item-no-bottom" :prop="'list.'+scope.$index+'.defaultNumber'"
                                    :rules="tableRules1.defaultNumber">
                        <div v-if="pageType === 'details'">
                          <span>{{ scope.row.defaultNumber }}{{ scope.row.valuationWay === 1 ? 'kg' : '件' }}以内，{{ scope.row.defaultPrice }}元</span>
                          <span v-if="scope.row.newNumber !== 0">，每增加{{scope.row.newNumber}}{{ scope.row.valuationWay === 1 ? 'kg' : '件' }}，增加运费{{scope.row.newPrice}}元</span>
                        </div>
                        <div class="flexRow temp list-input" v-else>
                          <el-input-number type="number" :step-strictly="true" :controls="false" :step="1" :min="0"
                                           v-model="scope.row.defaultNumber" placeholder="请输入"></el-input-number>
                          <span>{{ template.valuationWay === 1 ? 'kg' : '件' }}以内，</span>
                          <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01"
                                           :precision="2" :min="0" v-model="scope.row.defaultPrice"
                                           placeholder="请输入"></el-input-number>
                          <span>元，每增加</span>
                          <el-input-number type="number" :step-strictly="true" :controls="false" :step="1" :min="0"
                                           v-model="scope.row.newNumber" placeholder="请输入"></el-input-number>
                          <span>{{ template.valuationWay === 1 ? 'kg' : '件' }}，增加运费</span>
                          <el-input-number type="number" :step-strictly="true" :controls="false" :step="0.01" :min="0"
                                           :precision="2" v-model="scope.row.newPrice"
                                           placeholder="请输入"></el-input-number>
                          <span>元</span>
                        </div>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="操作" v-if="pageType !== 'details'">
                    <template slot-scope="scope">
                      <div class="flex flex_row flex_ju_center">
                        <el-popconfirm
                            @confirm="delRowAddress(scope.row,scope.$index)"
                            title="确定删除该数据吗？">
                          <span class="reference-style" slot="reference">删除</span>
                        </el-popconfirm>
                        <div class="border_center_left" @click="editRowAddress(scope.row,scope.$index)">修改地区</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="指定包邮条件" name="second">
              <div class="flexRow justifyContentSpac alignItems">
                <div class="table-title">运送地区、计费规则都可自行编辑</div>
                <div class="flexRow" v-if="pageType !== 'details'">
                  <el-button @click="delRuls">删除</el-button>
                  <el-button type="primary" @click="addRuls">添加地区</el-button>
                </div>
              </div>
              <el-form class="form-table" ref="ruleForm2" :model="form" :rules="tableRules2" :hide-required-asterisk="true">
                <el-table
                    border
                    :data="form.list1"
                    v-loading="tableLoading"
                    style="width: 100%"
                    :header-cell-style="this.utils.getRowClass"
                    @selection-change="handleSelectionChange1">
                  <el-table-column
                      align="center"
                      type="selection"
                      width="55">
                  </el-table-column>
                  <el-table-column prop="city" label="运送地区" min-width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <span>{{ scope.row.city ? scope.row.city : '-' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="计费规则" min-width="250">
                    <template slot-scope="scope">
                      <span class="flexRow" v-if="pageType === 'details'">
                        {{scope.row.ruleNumber}}{{scope.row.ruleType === 1?'件':scope.row.ruleType === 2?'kg':''}}包邮
                      </span>
                      <div class="flexRow list1-item" v-else>
                      <el-form-item class="item-no-bottom" label="满" :prop="'list1.'+scope.$index+'.ruleNumber'"
                                    :rules="tableRules2.ruleNumber">
                        <div class="flexRow temp list-input">
                          <el-input-number v-if="scope.row.ruleType === 1" type="number" :step-strictly="true"
                                           :controls="false" :step="1" :min="0"
                                           v-model="scope.row.ruleNumber" placeholder="请输入"></el-input-number>
                          <el-input-number v-if="scope.row.ruleType === 2" type="number" :step-strictly="true"
                                           :controls="false" :step="0.01"
                                           :precision="2" :min="0" v-model="scope.row.ruleNumber"
                                           placeholder="请输入"></el-input-number>
                          <el-select v-model="scope.row.ruleType" placeholder="请选择">
                            <el-option label="件" :value="1"></el-option>
                            <el-option label="kg" :value="2"></el-option>
                          </el-select>
                          <span>包邮</span>
                        </div>
                      </el-form-item>
                      <el-form-item class="item-no-bottom" label="未满足条件，运费为" :prop="'list1.'+scope.$index+'.ruleDefaultPrice'"
                                    :rules="tableRules2.ruleDefaultPrice">
                        <div class="flexRow temp list-input">
                          <el-input-number type="number" :step-strictly="true"
                                           :controls="false" :step="0.01"
                                           :precision="2" :min="0" v-model="scope.row.ruleDefaultPrice"
                                           placeholder="请输入"></el-input-number>
                          <span>元</span>
                        </div>
                      </el-form-item>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="操作" v-if="pageType !== 'details'">
                    <template slot-scope="scope">
                      <div class="flex flex_row flex_ju_center">
                        <el-popconfirm
                            @confirm="delRowAddress(scope.row,scope.$index)"
                            title="确定删除该数据吗？">
                          <span class="reference-style" slot="reference">删除</span>
                        </el-popconfirm>
                        <div class="border_center_left" @click="editRowAddress(scope.row,scope.$index)">修改地区</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="不能下单地区" name="third">
              <div class="flexRow justifyContentSpac alignItems">
                <div class="table-title">不可下单地区的用户无法购买商品</div>
                <div class="flexRow" v-if="pageType !== 'details'">
                  <el-button @click="delRuls">删除</el-button>
                  <el-button type="primary" @click="addRuls">添加地区</el-button>
                </div>
              </div>
              <el-table
                  class="form-table"
                  border
                  :data="form.list2"
                  v-loading="tableLoading"
                  style="width: 100%"
                  :header-cell-style="this.utils.getRowClass"
                  @selection-change="handleSelectionChange2">
                <el-table-column
                    align="center"
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column prop="city" label="不可下单地区" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <span>{{ scope.row.city ? scope.row.city : '-' }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="300" v-if="pageType !== 'details'">
                  <template slot-scope="scope">
                    <div class="flex flex_row flex_ju_center">
                      <el-popconfirm
                          @confirm="delRowAddress(scope.row,scope.$index)"
                          title="确定删除该数据吗？">
                        <span class="reference-style" slot="reference">删除</span>
                      </el-popconfirm>
                      <div class="border_center_left" @click="editRowAddress(scope.row,scope.$index)">修改地区</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <div class="footer-toolbar" v-if="pageType === 'details'">
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="footer-toolbar" v-else>
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button @click="sumbit" type="primary" :loading="btnLoading">确定</el-button>
    </div>

    <el-drawer
        title="选择地区"
        size="40%"
        :visible.sync="drawer"
        :before-close="handleCloseDrawer">
      <div class="drawer-wrap">
        <div class="drawer-item" v-for="(item,index) in cityData" :key="index">
          <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                       @change="handleCheckAllChange($event,index)">
            <span class="drawer-item-header">{{ item.header }}</span>
          </el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="item.checkedCities" @change="handleCheckedCitiesChange($event,index)">
            <el-checkbox v-for="city in item.cities" :label="city" :key="city">{{ city }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="drawer-footer">
        <el-button @click="handleSelectCities" type="primary" style="margin-left: 16px;">确定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
let _this;
import cityData from './address.js'

export default {
  data() {
    _this = this;
    const validateDefaultNumber = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入默认运费'));
      } else {
        callback();
      }
    };
    const validateRuleNumber = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入运费金额'));
      } else {
        callback();
      }
    };
    const validateRuleDefaultPrice = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入运费金额'));
      } else {
        callback();
      }
    };
    const validateFreight = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入运费金额'));
      } else {
        callback();
      }
    };
    const validateSimAddressList = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择发货地区'));
      } else {
        callback();
      }
    };
    return {
      cityData: [],
      drawer: false,
      drawerType: '', // add edit
      updCities: {}, // 当前修改地区的表格数据
      currentIndex: 0, // 当前修改地区的表格下标
      tempFlag: false,
      tableFlag1: false,
      tableFlag2: false,
      tableFlag3: false,
      rules: {
        defaultNumber: [{required: true, validator: validateDefaultNumber, trigger: 'change'}],
      },
      activeName: 'first',
      template: {
        simAddress: '请选择区域',
        simAddressList: [],
        title: '',
        type: 0,
        valuationWay: 0,
        defaultNumber: 0,
        defaultPrice: 0,
        newNumber: 0,
        newPrice: 0,
        freight: 0,
      },
      form: {
        list: [],
        list1: [],
        list2: [],
      },
      tempRules: {
        title: [
          {required: true, message: '请输入模板名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 10 个字符', trigger: 'blur'}
        ],
        simAddressList: [{required: true, validator: validateSimAddressList, trigger: 'blur'}],
        defaultNumber: [{required: true, validator: validateDefaultNumber, trigger: 'blur'}],
        freight: [{required: true, validator: validateFreight, trigger: 'blur'}],
      },
      tableRules1: {
        defaultNumber: [{required: true, validator: validateDefaultNumber, trigger: 'change'}],
      },
      tableRules2: {
        ruleNumber: [{required: true, validator: validateRuleNumber, trigger: 'change'}],
        ruleDefaultPrice: [{required: true, validator: validateRuleDefaultPrice, trigger: 'change'}],
      },
      upData: {},
      orderId: '',
      dialogImageUrl: '',
      tableList: [],
      tableLoading: false,
      dialogVisible: false,
      btnLoading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      multipleSelection1: [],
      multipleSelection2: [],
      multipleSelection3: [],
      templateId: '',
      pageType: '',
    };
  },
  filters: {
    getOrderStatus(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getOrderStatusType(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].color;
      }
    },
    getEvaStatus(value) {
      const data = _this.evaStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.templateId = this.$route.query.id;
    }
    if (this.$route.query.type) {
      this.pageType = this.$route.query.type;
      if (this.pageType !== 'add') {
        this.getData();
      }
    }
  },
  methods: {
    // 获取模板信息
    getData() {
      let _this = this;
      _this.$api
          .getTempDetails({id: _this.templateId})
          .then(res => {
            if (res.data.code == 200) {
              _this.upData = res.data.data;
              // 将价格都乘以100，变成分
              _this.upData.template.freight = _this.upData.template.freight /100;
              _this.upData.template.defaultPrice = _this.upData.template.defaultPrice /100;
              _this.upData.template.newPrice = _this.upData.template.newPrice /100;

              if(_this.upData.list && _this.upData.list.length>0) {
                _this.upData.list.forEach(item => {
                  item.defaultPrice = item.defaultPrice / 100;
                  item.newPrice = item.newPrice / 100;
                })
              }
              if(_this.upData.list1 && _this.upData.list1.length>0) {
                _this.upData.list1.forEach(item => {
                  // 1价格 2数量
                  if(item.ruleType === 1) {
                    item.ruleNumber = item.ruleNumber / 100;
                  }
                  item.ruleDefaultPrice = item.ruleDefaultPrice / 100;
                })
              }
              const data = JSON.parse(JSON.stringify(_this.upData));
              _this.template = data.template;
              _this.template.simAddressList = data.template.simAddress.split(',');
              _this.form = {
                list: data.list,
                list1: data.list1,
                list2: data.list2,
              }
            }
          }).catch(() => {
      });
    },
    // 切换Tab之前的钩子
    handleBeforeLeave(activeName, oldActiveName) {
      let _this = this;
      if(_this.pageType === 'details') {
        return
      }
      if (oldActiveName === 'first') {
        _this.tableFlagVerify1();
        if (!_this.tableFlag1) {
          _this.$message.warning('请将内容填写完整后，再进行其他操作！');
          return false;
        }
      }

      if (oldActiveName === 'second') {
        _this.tableFlagVerify2();
        if (!_this.tableFlag2) {
          _this.$message.warning('请将内容填写完整后，再进行其他操作！');
          return false;
        }
      }
      if (oldActiveName === 'third') {
        _this.form.list2.forEach(item => {
          if (item.city === '') {
            _this.$message.warning('请将内容填写完整后，再进行其他操作！');
          }
        })
      }
    },
    // 表格多选框 1
    handleSelectionChange(val) {
      let _this = this;
      _this.multipleSelection1 = val;
    },
    // 表格多选框 2
    handleSelectionChange1(val) {
      let _this = this;
      _this.multipleSelection2 = val;
    },
    // 表格多选框 3
    handleSelectionChange2(val) {
      let _this = this;
      _this.multipleSelection3 = val;
    },
    // 多选删除地区
    delRuls() {
      let _this = this;


      if (_this.activeName === 'first') {
        if (_this.form.list.length === 0) {
          _this.$message.error('暂无数据删除');
          return
        }

        if (_this.multipleSelection1.length === 0) {
          _this.$message.error('请至少选择一条表格数据');
          return
        }
      }

      if (_this.activeName === 'second') {
        if (_this.form.list1.length === 0) {
          _this.$message.error('暂无数据删除');
          return
        }

        if (_this.multipleSelection2.length === 0) {
          _this.$message.error('请至少选择一条表格数据');
          return
        }
      }

      if (_this.activeName === 'third') {
        if (_this.form.list2.length === 0) {
          _this.$message.error('暂无数据删除');
          return
        }

        if (_this.multipleSelection3.length === 0) {
          _this.$message.error('请至少选择一条表格数据');
          return
        }
      }


    },
    // 添加地区
    addRuls() {
      let _this = this;
      _this.drawer = true;
      const data = JSON.parse(JSON.stringify(cityData));
      data.forEach(item => {
        item.checkAll = false;
        item.isIndeterminate = false;
        item.checkedCities = [];
      })
      _this.drawerType = 'addCity';
      _this.cityData = data;
    },
    // 删除列表地区
    delRowAddress(row, i) {
      let _this = this;
      if (_this.activeName === 'first') {
        _this.form.list.splice(i, 1);
      }
      if (_this.activeName === 'second') {
        _this.form.list1.splice(i, 1);
      }
      if (_this.activeName === 'third') {
        _this.form.list2.splice(i, 1);
      }
    },
    // 添加列表地区
    editRowAddress(row, i) {
      let _this = this;
      _this.updCities = JSON.parse(JSON.stringify(row));
      _this.currentIndex = i;

      const data = JSON.parse(JSON.stringify(cityData));
      const selectCities = _this.updCities.city.split(',');

      // 循环匹配城市
      data.forEach(item => {
        selectCities.forEach(city => {
          const selectData = item.cities.filter(value => value === city);

          if (selectData.length > 0) {
            item.checkedCities.push(...selectData)
          }
        })
      })
      _this.cityData = data;
      _this.drawerType = 'editCity';
      _this.drawer = true;
    },
    // 关闭左侧地址抽屉
    handleCloseDrawer(done) {
      let _this = this;
      _this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    // 选择地区
    handleSelectCities() {
      let _this = this;
      const checkedAllCities = []; // 所选的所有城市

      for (let i = 0; i < _this.cityData.length; i++) {
        if (_this.cityData[i].checkedCities.length === 0) {
          continue;
        }
        checkedAllCities.push(..._this.cityData[i].checkedCities);
      }
      if (checkedAllCities.length === 0) {
        _this.$message.error('请至少选择一个地区');
        return
      }

      if (_this.drawerType === 'addCity') {
        if (_this.activeName === 'first') {
          _this.form.list.push({
            city: checkedAllCities.toString(),
            defaultNumber: '',
            defaultPrice: '',
            newNumber: '',
            newPrice: '',
            rule: 0,
          });
        }
        if (_this.activeName === 'second') {
          _this.form.list1.push({
            city: checkedAllCities.toString(),
            ruleDefaultPrice: 0,
            ruleNumber: 0,
            ruleType: 1,
            rule: 1,
          });
        }
        if (_this.activeName === 'third') {
          _this.form.list2.push({
            city: checkedAllCities.toString(),
            ruleNumber: 0,
            ruleType: 1,
            rule: 2,
          });
        }

      } else {
        _this.updCities.city = checkedAllCities.toString();
        if (_this.activeName === 'first') {
          _this.form.list[_this.currentIndex].city = checkedAllCities.toString();
        }
        if (_this.activeName === 'second') {
          _this.form.list1[_this.currentIndex].city = checkedAllCities.toString();
        }
        if (_this.activeName === 'third') {
          _this.form.list2[_this.currentIndex].city = checkedAllCities.toString();
        }
      }

      _this.drawer = false;
    },
    handleCheckAllChange(val, i) {
      let _this = this;
      _this.cityData[i].checkedCities = val ? _this.cityData[i].cities : [];
      _this.cityData[i].isIndeterminate = false;
    },
    handleCheckedCitiesChange(value, i) {
      let _this = this;
      let checkedCount = value.length; // 选择的城市数量
      _this.cityData[i].checkAll = checkedCount === _this.cityData[i].cities.length;
      _this.cityData[i].isIndeterminate = checkedCount > 0 && checkedCount < _this.cityData[i].cities.length;
    },
    // form验证
    tempVerify() {
      let _this = this;
      _this.$refs["tempRuleForm"].validate(valid => {
        if (valid) {
          _this.tempFlag = true;
        } else {
          _this.tempFlag = false;
        }
      });
    },
    // 指定地区运费验证
    tableFlagVerify1() {
      let _this = this;
      _this.$refs["ruleForm1"].validate(valid => {
        if (valid) {
          _this.tableFlag1 = true;
        } else {
          _this.tableFlag1 = false;
        }
      });
    },
    // 指定包邮条件验证
    tableFlagVerify2() {
      let _this = this;
      _this.$refs["ruleForm2"].validate(valid => {
        if (valid) {
          _this.tableFlag2 = true;
        } else {
          _this.tableFlag2 = false;
        }
      });
    },
    // 不能下单地区验证
    tableFlagVerify3() {
      let _this = this;
      _this.$refs["tableRules3"].validate(valid => {
        if (valid) {
          _this.tableFlag3 = true;
        } else {
          _this.tableFlag3 = false;
        }
      });
    },
    // 提交
    sumbit() {
      let _this = this;

      _this.btnLoading = true;
      _this.tempVerify();
      if (_this.activeName === 'first') {
        _this.tableFlagVerify1();
      }
      if (_this.activeName === 'second') {
        _this.tableFlagVerify2();
      }
      if (_this.activeName === 'third') {
        _this.form.list2.forEach(item => {
          if (item.city === '') {
            _this.$message.warning('请将内容填写完整后，再进行其他操作！');
          }
        })
      }

      const templateData = JSON.parse(JSON.stringify(_this.template));
      templateData.simAddress = templateData.simAddressList.toString();
      delete templateData.simAddressList;

      // const list = {};
      // const listData = JSON.parse(JSON.stringify(_this.form));
      //
      // for (let key in listData) {
      //   if (listData[key]) {
      //     list[key] = listData[key];
      //   }
      // }

      const newRequestData = {
        template: templateData,
        ..._this.form
      }

      const requestData = JSON.parse(JSON.stringify(newRequestData));
      // 将价格都乘以100，变成分
      requestData.template.freight = requestData.template.freight *100;
      requestData.template.defaultPrice = requestData.template.defaultPrice *100;
      requestData.template.newPrice = requestData.template.newPrice *100;

      if(requestData.list && requestData.list.length>0) {
        requestData.list.forEach(item => {
          item.defaultPrice = item.defaultPrice *100;
          item.newPrice = item.newPrice *100;
        })
      }
      if(requestData.list1 && requestData.list1.length>0) {
        requestData.list1.forEach(item => {
          // 1价格 2数量
          if(item.ruleType === 1) {
            item.ruleNumber = item.ruleNumber *100;
          }
          item.ruleDefaultPrice = item.ruleDefaultPrice *100;
        })
      }
      if (_this.pageType == 'add') {
        _this.$api.addTemp(requestData).then(res => {
          if (res.data.code == 200) {
            _this.$message.success("添加成功");
            _this.btnLoading = false;
            _this.$router.go(-1);
          }
        }).catch(() => {
          _this.btnLoading = false;
        });
      }
      if (_this.pageType == 'edit') {
        _this.$api.editTemp(requestData).then(res => {
          if (res.data.code == 200) {
            _this.$message.success("修改成功");
            _this.btnLoading = false;
            _this.$router.go(-1);
          }
        }).catch(() => {
          _this.btnLoading = false;
        });
      }
    },
  }
};
</script>
<style scoped lang="less">
.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.content_li {
  padding: 0;
}

.content_li-padding {
  padding: 30px;
  box-sizing: border-box;
}

.content_li-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 2;
}

.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}

/deep/ .el-tag {
  color: #fff !important;
  border: none;
}

/deep/ .area-select {
  width: 234px;

  /deep/ .area-selected-trigger {
    padding: -20px 30px 0 30px;
  }
}

/deep/ .el-form-item {
  width: 100%;
}

.list-input {
  /deep/ .el-input-number {
    width: 100px;
  }
}

.item-no-bottom {
  margin-bottom: 0;
}

.temp > span {
  display: inline-block;
  margin: 0 10px;
}

//.temp {
//  /deep/ .el-input {
//    width: 200px;
//  }
//  /deep/ .el-input__inner {
//    width: 200px;
//  }
//}

.template-title {
  width: 235px;
}

.temp-details {
  display: flex;
  justify-content: flex-start;
}

.temp-details {
  /deep/ .el-form-item {
    //width: 280px;
  }
}

.table-title {
  font-size: 14px;
}

.form-table {
  margin: 20px 0;
}

/deep/ .el-drawer__header {
  font-size: 15px;
}

.drawer-wrap {
  font-size: 15px;
  margin: 0 20px;
  position: relative;
  height: 100%;
}

.drawer-item:nth-of-type(n+2) {
  margin-top: 20px;
}

.drawer-item-header {
  font-weight: 900;
}

.drawer-footer {
  position: absolute;
  bottom: 30px;
  right: 20px;
}

.list-input {
  /deep/ .el-select {
    width: 100px;
    margin-left: 10px;
  }
}

.reference-style {
  cursor: pointer;
  color: #1890ff;
}
/deep/.area-select {
  display: flex;
  align-items: center;
}
/deep/.area-select .area-selected-trigger {
  height:initial;
}
.list1-item {
  /deep/ .el-form-item {
    width: inherit;
    width: 1.5625rem;

  }
  /deep/ .el-input__inner {
    width: 100px;
  }
}
</style>
